<div class="notification-bar d-flex px-3 align-items-center no-gutters justify-content-center"
  [ngClass]="{
  'border border-error': type === 'ERROR',
  'border border-tertiary-base': type === 'INFO',
  'bg-yellow border-none text-promo-dark justify-content-center': type === 'PROMO'
  }">
  <div class="col-auto d-flex align-items-center justify-content-center mr-1"
    [ngClass]="{
    'text-promo-dark': type === 'PROMO'
    }">
    <mat-icon *ngIf="type === 'PROMO'">star</mat-icon>
    <mat-icon *ngIf="type === 'INFO'">chat_bubble_outline</mat-icon>
  </div>
  <div class="col-auto flex-shrink-1"
    [ngClass]="{
    'text-promo-dark': type === 'PROMO'
    }">
    {{message}}
  </div>
</div>