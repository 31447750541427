import { Component, Input } from '@angular/core';

@Component({
  selector: 'ss-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss'],
  standalone: false,
})
export class InlineLoaderComponent {
  @Input() diameter = 30;
  @Input() showOverlay = false;
  @Input() hideSpinner = false;
}
