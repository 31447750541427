import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Collection, Sample } from '@common/core/models';
import { AppSessionService, DialogsService, LoaderService, StoreService } from '@web/core/services';

@Component({
  selector: 'ss-super-sample-button',
  template: './super-sample-button.component.html',
  styleUrls: ['./super-sample-button.component.scss'],
  standalone: false,
})
export class SuperSampleButtonComponent {
  @Input() sample?: Sample;
  @Input() samples?: Sample[];
  @Input() collection?: Collection;
  @Input() showLabel = false;
  @Input() IsInMatMenu = false;
  @Output() beforeAction = new EventEmitter<boolean>();
  @Output() actionCompleted = new EventEmitter<boolean>();
  @Output() bulkActionCompleted = new EventEmitter<boolean>();
  protected loaderService: LoaderService;
  protected dialogsService: DialogsService;
  protected storeService: StoreService;
  public appSessionService: AppSessionService;

  constructor(protected injector: Injector) {
    this.loaderService = this.injector.get(LoaderService);
    this.dialogsService = this.injector.get(DialogsService);
    this.appSessionService = this.injector.get(AppSessionService);
    this.storeService = this.injector.get(StoreService);
  }

  get isBulk(): boolean {
    return !!this.samples;
  }

  get isHandsetLayout() {
    return this.storeService.store.activeBreakpoints.isHandsetLayout;
  }

  preventDefault($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
