<!-- Mobile player bar -->
<div (click)="onOpenExtendedPlayer()"
  class="d-flex flex-nowrap align-items-center mobile-player"
  [hidden]="isPlayerExpanded">

  <!--  Linear progress bar -->
  <div class="mobile-progress-bar"
    *ngIf="sample">
    <mat-progress-bar *ngIf="isLoading"
      color="primary"
      mode="buffer"></mat-progress-bar>

    <mat-progress-bar *ngIf="!isLoading"
      color="primary"
      mode="determinate"
      [value]="progressPercentage"></mat-progress-bar>
  </div>

  <!-- Cover -->
  <ss-sample-cover [sample]="sample"></ss-sample-cover>

  <!-- Label -->
  <div class="col-auto flex-shrink-1 flex-grow-1 trick-min-width-0 pr-0 d-flex align-items-center">
    <span data-testid="sample-name"
      class="mat-label-small label">{{sample?.label}}</span>
  </div>

  <div class="d-flex"
    *ngIf="sample">
    <button mat-icon-button
      data-testid="pause-button"
      *ngIf="isPlaying"
      [disabled]="isLoading"
      (click)="onPauseSample($event)">
      <mat-icon class="material-icons-outlined">pause</mat-icon>
    </button>

    <button mat-icon-button
      data-testid="play-button"
      *ngIf="!isPlaying"
      [disabled]="isLoading"
      (click)="onPlaySample($event)">
      <mat-icon class="material-icons-outlined">play_arrow</mat-icon>
    </button>

    <ss-sample-favorite-button *ngIf="!sample?.isPackSample"
      [sample]="sample"></ss-sample-favorite-button>
  </div>
</div>

<!-- Mobile player extended -->
<div class="d-flex flex-column justify-content-between mobile-player-expanded py-2"
  [hidden]="!isPlayerExpanded">

  <!-- top row -->
  <div>
    <!-- Title and close button -->
    <div class="d-flex flex-nowrap align-items-center">
      <div class="flex-grow-1 flex-shrink-1 break-word trick-min-width-0">
        {{sample?.label}}
      </div>

      <div>
        <button mat-icon-button
          (click)="onCloseExtendedPlayer()">
          <mat-icon class="material-icons-outlined">expand_more</mat-icon>
        </button>
      </div>
    </div>

    <!-- buttons -->
    <div class="d-flex justify-content-around">
      <div *ngIf="!sample?.isPackSample">
        <ss-sample-favorite-button [sample]="sample"></ss-sample-favorite-button>
        <div class="mat-label-small text-center">Favorite</div>
      </div>

      <div class="d-flex flex-column align-items-center">
        <ss-sample-download-button [sample]="sample"></ss-sample-download-button>
        <div class="mat-label-small text-center">Download</div>
      </div>

      <div *ngIf="!sample?.isPackSample">
        <ss-sample-menu-button [sample]="sample"
          [mobileMenuBottomSheetConfig]="mobileMenuBottomSheetConfig"
          (actionCompleted)="onCloseExtendedPlayer()"></ss-sample-menu-button>
        <div class="mat-label-small text-center">More</div>
      </div>
    </div>
  </div>

  <!-- middle row -->
  <div class="my-2 d-flex flex-column justify-content-center flex-grow-1">
    <!-- Cover -->
    <div class="d-flex justify-content-center mb-3 flex-shrink-1">
      <ss-sample-cover [sample]="sample"
        [imageWidth]="255"
        size="80%"></ss-sample-cover>
    </div>

    <!-- Tags -->
    <div class="mb-3 d-flex flex-no-wrap overflow-x-auto">
      <ss-tag *ngFor="let tag of (sample?.tags)"
        [label]="tag.label"
        class="mr-1"></ss-tag>
    </div>

    <!-- key bpm -->
    <div class="text-center">
      <span *ngIf="sample?.key">Key: {{sample.key}} {{sample.scale | titlecase}}</span>&nbsp;
      <span *ngIf="sample?.bpm">Bpm: {{sample.bpm}}</span>
    </div>
  </div>

  <!-- bottom row -->
  <div>
    <!-- sound wave -->
    <div #waveform></div>

    <!-- Timer -->
    <div class="d-flex mat-label-small justify-content-between">
      <div>{{currentTimeAsMilliseconds | date:'mm:ss'}}</div>
      <div *ngIf="duration">{{durationAsMilliseconds | date:'mm:ss'}}</div>
    </div>

    <!-- buttons -->
    <div class="d-flex no-gutters">
      <div class="col-6">
        <!-- Volume -->
        <ss-sample-volume (toggleVolume)="onToggleVolume($event)"
          [(volume)]="volume"
          [sample]="sample"
          [isLoading]="isLoading"
          [showToggleVolumeButton]="false"></ss-sample-volume>
      </div>

      <div class="col d-flex justify-content-center">
        <button mat-icon-button
          data-testid="previous-sample-button"
          *ngIf="showPreviousSampleButton"
          [disabled]="!sample || isLoading"
          (click)="onPreviousSample($event)">
          <mat-icon class="material-icons-outlined">skip_previous</mat-icon>
        </button>

        <button mat-icon-button
          *ngIf="!isPlaying"
          [disabled]="!sample || isLoading"
          (click)="onPlaySample($event)"
          class="play-pause-button">
          <mat-icon class="material-icons-outlined scaled">play_arrow</mat-icon>
        </button>
        <button mat-icon-button
          *ngIf="isPlaying"
          [disabled]="!sample || isLoading"
          (click)="onPauseSample($event)"
          class="play-pause-button">
          <mat-icon class="material-icons-outlined scaled">pause</mat-icon>
        </button>

        <button mat-icon-button
          data-testid="next-sample-button"
          *ngIf="showNextSampleButton"
          [disabled]="!sample || isLoading"
          (click)="onNextSample($event)">
          <mat-icon class="material-icons-outlined">skip_next</mat-icon>
        </button>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <button mat-icon-button
          *ngIf="showLoopButton"
          [disabled]="!sample || isLoading"
          [class]="isLooping? 'btn-tertiary' :''"
          (click)="onToggleLoop($event)">
          <mat-icon class="material-icons-outlined">repeat</mat-icon>
        </button>
      </div>
    </div>

  </div>

</div>