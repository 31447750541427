import { Component, Input } from '@angular/core';
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { EBitfCloseEventStatus } from '@web/enums';

@Component({
  selector: 'ss-info-side-panel',
  templateUrl: './info-side-panel.component.html',
  styleUrls: ['./info-side-panel.component.scss'],
  standalone: false,
})
export class InfoSidePanelComponent {
  @Input() message: string;

  constructor(private bitfMatSidenavService: BitfMatSidenavService) {}

  onCloseSidenav() {
    this.bitfMatSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }
}
