import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AiGenerate, Sample } from '@common/core/models';
import { AiService } from '@common/core/services/api/ai.service';
import { EBitfCloseEventStatus, EBitfUiMessageType, ESampleGeneratedBy } from '@common/enums';
import { StorageService, ToastMessagesService } from '@web/core/services';
import { IBitfCloseEvent } from '@web/interfaces';

@Component({
  selector: 'ss-ai-generation-settings-dialog',
  templateUrl: './ai-generation-settings-dialog.component.html',
  styleUrls: ['./ai-generation-settings-dialog.component.scss'],
  standalone: false,
})
export class AiGenerationSettingsDialogComponent implements OnInit {
  form: FormGroup;
  eSampleGeneratedBy = ESampleGeneratedBy;

  constructor(
    public dialogRef: MatDialogRef<AiGenerationSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { sample: Sample; title: string; isEditMode?: boolean },
    private toastMessagesService: ToastMessagesService,
    private aiService: AiService,
    protected storageService: StorageService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }

  async onCopyPrompt(prompt: string) {
    try {
      await navigator.clipboard.writeText(prompt);
      this.toastMessagesService.show({
        type: EBitfUiMessageType.SUCCESS,
        title: `Prompt copied to clipboard`,
      });
    } catch (err) {
      this.toastMessagesService.show({
        type: EBitfUiMessageType.ERROR,
        title: `Error copy Prompt`,
      });
    }
  }

  onGenerateVariation() {
    if (this.form.invalid) {
      return;
    }

    this.aiService
      .action<AiGenerate>({
        action: 'generate',
        body: { ...this.form.value },
        modelMapper: 'ai-generate',
      })
      .subscribe();

    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.form.value,
    } as IBitfCloseEvent<{ variationAmount: number; conditioningPrompt: string }>);

    this.toastMessagesService.show({
      title: '',
      message: 'Variation in progress',
      type: EBitfUiMessageType.SUCCESS,
    });
  }

  private initForm() {
    this.form = new FormGroup({
      sampleReferenceId: new FormControl(this.dialogData?.sample?.id),
      variationAmount: new FormControl(0),
      conditioningPrompt: new FormControl(''),
      prompt: new FormControl(this.dialogData?.sample?.aiGeneration?.prompt || ''),
      aiModelId: new FormControl(
        this.dialogData?.sample?.aiGeneration?.aiModelId || this.storageService?.data?.aiModel?.id || ''
      ),
    });
  }
}
