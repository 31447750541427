import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { TouchEventsService } from '@services';

@Directive({
  selector: '[bitfTouchEvent]',
  standalone: false,
})
export class BitfTouchEventDirective implements OnInit {
  @Input() bitfTouchEvent: string;

  private touchItemKey: string;
  private isTouchDeviceOnly = false;

  constructor(private touchEventsService: TouchEventsService) {}

  ngOnInit() {
    this.touchItemKey = this.bitfTouchEvent;
  }

  @HostListener('touchstart') touchstart(): void {
    this.isTouchDeviceOnly = true;
    this.touchEventsService.addItem(this.touchItemKey);
  }

  @HostListener('touchend') touchend(): void {
    this.isTouchDeviceOnly = true;
    this.touchEventsService.removeItem(this.touchItemKey);
  }

  @HostListener('touchcancel') touchcancel(): void {
    this.isTouchDeviceOnly = true;
    this.touchEventsService.removeItem(this.touchItemKey);
  }

  @HostListener('mousedown') mousedown(): void {
    if (this.isTouchDeviceOnly) {
      return;
    }
    this.touchEventsService.addItem(this.touchItemKey);
  }

  @HostListener('mouseup') mouseup(): void {
    if (this.isTouchDeviceOnly) {
      return;
    }
    this.touchEventsService.removeItem(this.touchItemKey);
  }
}
