import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Sample, Store } from '@common/core/models';
import { EWebStoreActions } from '@web/enums';
import { SuperAudioPlayerComponent } from '../super-audio-player/super-audio-player.component';
import { AppSessionService } from '@web/core/services';
import { environment } from '@env/environment';
@Component({
  selector: 'ss-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  standalone: false,
})
export class AudioPlayerComponent extends SuperAudioPlayerComponent {
  @Input() showPreviousSampleButton = true;
  @Input() showNextSampleButton = true;
  @Input() showLoopButton = true;
  @Input() sample: Sample;
  @Output() previousSample = new EventEmitter();
  @Output() nextSample = new EventEmitter();
  @Input()
  set volume(value: number) {
    // NOTE: in the first tick is not defined
    this.waveSurfer?.setVolume(value);
    this._volume = value;
  }
  get volume(): number {
    return this._volume;
  }
  get progressPercentage(): number {
    return Math.ceil((this.currentTime / this.duration) * 100);
  }
  get currentTimeAsMilliseconds(): number {
    return (this.currentTime ?? 0) * 1000;
  }
  isLooping = false;
  isPlaying = false;
  _volume = environment.name.includes('staging') || environment.name.includes('production') ? 0.8 : 1;
  lastVolume = 0.0;
  currentTime = 0;
  public appSessionService: AppSessionService;

  constructor(protected injector: Injector) {
    super(injector);
    this.appSessionService = injector.get(AppSessionService);
    this.storeService.setStore((store: Store) => {
      store.audioPlayer = this;
    }, EWebStoreActions.SET_WAVE_SURFER);
  }

  onPreviousSample($event = undefined) {
    this.previousSample.emit();
    this.preventDefault($event);
  }

  onNextSample($event = undefined) {
    this.nextSample.emit();
    this.preventDefault($event);
  }

  onPlaySample($event = undefined) {
    this.waveSurfer.play();

    if (this.useAudioElement) {
      setTimeout(() => {
        this.filters.forEach(filter => {
          filter.gain.value = 0;
        });
      }, 100);
    }
    this.preventDefault($event);
  }

  onPauseSample($event = undefined) {
    if (this.useAudioElement) {
      this.filters.forEach(filter => {
        filter.gain.value = -40;
      });
    }
    this.waveSurfer.pause();
    setTimeout(() => {}, 1000);
    this.preventDefault($event);
  }

  onToggleLoop($event = undefined) {
    this.isLooping = !this.isLooping;
    this.preventDefault($event);
  }

  onToggleVolume($event = undefined) {
    if (this.volume === 0) {
      this.volume = this.lastVolume;
    } else {
      this.lastVolume = this.volume;
      this.volume = 0;
    }
    this.preventDefault($event);
  }

  loadNewSample() {
    // NOTE this is needed to prevent audio glitch on destroying
    // an audio player that is playing
    if (this?.waveSurfer?.isPlaying) {
      this.onPauseSample();
      setTimeout(() => {
        this.doLoadNewSample();
      }, 300);
    } else {
      this.doLoadNewSample();
    }
  }

  private doLoadNewSample() {
    super.disposeWaveSurfer();

    // Extend the default options
    Object.assign(this.waveSurferOptions, {
      progressColor: '#9e3385',
      container: this.waveform.nativeElement,
      url: this.parseSampleUrl(),
    });

    super.createWaveSurfer();

    this.waveSurfer.setVolume(this.volume);

    this.attachWaveSurferEvents();
  }

  private attachWaveSurferEvents() {
    this.waveSurfer.on('ready', duration => {
      super.onWaveSurferReady({ duration });
    });

    this.waveSurfer.on('interaction', () => {
      this.waveSurfer.play();
    });

    this.waveSurfer.on('timeupdate', currentTime => {
      this.currentTime = currentTime;
    });

    this.waveSurfer.on('play', () => {
      this.isPlaying = true;
    });

    this.waveSurfer.on('pause', () => {
      this.isPlaying = false;
    });

    this.waveSurfer.on('finish', () => {
      if (this.isLooping) {
        this.waveSurfer.seekTo(0);
        this.waveSurfer.play();
      }
    });
  }
}
