import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, Tag } from '@common/core/models';
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import {
  EBitfCloseEventStatus,
  EBitfPQueryComparators,
  EBitfSidenavMode,
  EBitfSidenavPosition,
  EWebStoreActions,
  eStoreActions,
} from '@web/enums';
import { Observable, of, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { SearchSamplesSuperFilterComponent } from '../search-samples-super-filter/search-samples-super-filter.component';
import { StoreService } from '@common/core/services';
import { IBitfApiRequest } from '@web/interfaces';
import { MobileFiltersSidenavComponent } from '../mobile-filters-sidenav/mobile-filters-sidenav.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'ss-search-samples-search-bar',
  templateUrl: './search-samples-search-bar.component.html',
  styleUrls: ['./search-samples-search-bar.component.scss'],
  standalone: false,
})
export class SearchSamplesSearchBarComponent extends SearchSamplesSuperFilterComponent implements OnInit {
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: true })
  autoComplete: MatAutocompleteTrigger;

  @Input() label: string;
  promptValue: string | Tag;
  promptControl = new FormControl('');
  tags: Observable<Tag[]>;

  get isHandsetLayout() {
    return this.storeService.store.activeBreakpoints.isHandsetLayout;
  }

  get isFiltersSidebarOpen() {
    return this.storeService.store.isDesktopFiltersSidenavOpen;
  }

  constructor(
    protected injector: Injector,
    private bitfMatSidenavService: BitfMatSidenavService,
    private storeService: StoreService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.tags = this.promptControl.valueChanges.pipe(
      debounce(() => timer(500)),
      distinctUntilChanged(),
      // NOTE: bypass new api fetch if the user selects a tag from the autocomplete
      filter(() => !(this.promptValue as Tag)?.id),
      switchMap(prompt => {
        if (!prompt || prompt.length < 2) {
          return of([]);
        } else {
          // NOTE: we read filters from the part because are needed to get new facets
          const { query } = this.apiCallStateService.getApiRequest(this.apiCallStateName);
          const autocompleteQuery = [...query];
          // NOTE: we patch the first query param which is the prompt
          // with the new user string
          autocompleteQuery[0] = {
            name: 'prompt',
            value: prompt,
            comparator: EBitfPQueryComparators.CONTAINS,
            operator: null,
          };

          const request: IBitfApiRequest = { query: autocompleteQuery };

          // NOTE: in case we are inside a pack or collection we've to include the id
          if (this.resourceId) {
            request.id = this.resourceId;
          }

          return this.service.getAutocomplete(request).pipe(map(response => response.content));
        }
      })
      // map(() => [])
    );

    window.addEventListener('scroll', this.scrollEvent, true);

    // NOTE: if the mobile filter bar is open and the user streatch the window to go
    // in desktop view we've to close the sidenav
    this.storeService.selectStore([eStoreActions.BREAKPOINT]).subscribe(event => {
      if (event.store.activeBreakpoints.isWebLayout) {
        this.bitfMatSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
      }
    });
  }

  onSearch() {
    if (this.promptValue === '' || this.promptValue === null) {
      return;
    }
    if (this.promptValue instanceof Tag) {
      const newTag = new Tag({
        id: this.promptValue.id,
        label: this.promptValue.label,
        category: this.promptValue.category,
      });
      this.filtersRequestPart.data.tags.push(this.promptValue);
    } else {
      this.filtersRequestPart.data.prompt = this.promptValue;
    }
    this.updateFilters();
    this.promptControl.reset();
  }

  onClearPrompt() {
    this.promptControl.reset();
  }

  displayFn(tag: Tag): string {
    return tag?.label;
  }

  onOpenFilter() {
    if (this.storeService.store.activeBreakpoints.isHandsetLayout) {
      setTimeout(() => {
        this.bitfMatSidenavService
          .open({
            component: MobileFiltersSidenavComponent,
            componentData: {
              apiCallStateName: this.apiCallStateName,
              service: this.service,
              closeFn: () => this.bitfMatSidenavService.close({ status: EBitfCloseEventStatus.CLOSE }),
            },
            sidenavOptions: {
              mode: EBitfSidenavMode.OVER,
              position: EBitfSidenavPosition.END,
              disableClose: false,
              autoFocus: false,
            },
          })
          .subscribe();
      }, 100);
    } else {
      this.storeService.setStore((store: Store) => {
        store.isDesktopFiltersSidenavOpen = true;
      }, EWebStoreActions.TOGGLE_DESKTOP_FILTERS_SIDENAV);
    }
  }

  private scrollEvent = (): void => {
    if (this.autoComplete.panelOpen) this.autoComplete.closePanel();
  };
}
