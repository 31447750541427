import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sample } from '@common/core/models';

@Component({
  selector: 'ss-sample-volume',
  templateUrl: './sample-volume.component.html',
  styleUrls: ['./sample-volume.component.scss'],
  standalone: false,
})
export class SampleVolumeComponent {
  @Input() sample: Sample;
  @Input() isLoading: boolean;
  @Input() volume: number;
  @Input() showToggleVolumeButton = true;
  @Output() volumeChange = new EventEmitter<number>();
  @Output() toggleVolume = new EventEmitter();
}
