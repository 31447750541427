<div class="d-flex align-items-center h-100"
  *ngIf="sample">

  <mat-progress-bar color="primary"
    *ngIf="isLoading"
    mode="buffer"></mat-progress-bar>

  <div class="flex-grow-1"
    [hidden]="isLoading"
    #waveform></div>

  <div *ngIf="duration"
    class="mr-2 mat-label-small col-auto d-none d-sm-block">
    {{durationAsMilliseconds | date:'mm:ss'}}
  </div>
</div>