import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@constants';

import { TranslateService } from '@ngx-translate/core';

import { IBitfFormControlTranslations } from './bitf-form-control-translate.interface';

@Injectable()
@Pipe({
  name: 'bitfFormControlTranslate',
  standalone: false,
})
export class BitfFormControlTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(errors: IBitfFormControlTranslations): any {
    if (!errors) {
      return '';
    }
    if (errors?.keys) {
      // NOTE: Used for form-item component
      // errors will have this shape, defined in bitf form control
      // this.validationErrors = {
      //   keys = [],
      //   params = {},
      // };
      return errors.keys.map(key => this.translateService.instant(key, errors.params)).join(', ');
    } else {
      //  NOTE: Used for default Angular errors
      return Object.keys(errors)
        .map(key => this.translateService.instant(CONSTANTS.VALIDATION_MESSAGES[key], errors[key]))
        .join(', ');
    }
  }
}
