import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter, Tag } from '@common/core/models';

@Component({
  selector: 'ss-search-samples-tags-selector',
  templateUrl: './search-samples-tags-selector.component.html',
  styleUrls: ['./search-samples-tags-selector.component.scss'],
  standalone: false,
})
export class SearchSamplesTagsSelectorComponent {
  @Input() filters: Filter;
  @Input() hasChildren = false;
  @Input() filterName: string;
  @Output() change = new EventEmitter();

  get tags(): Tag[] {
    return this.filters[this.filterName];
  }

  onTagChange() {
    this.change.emit();
  }
}
