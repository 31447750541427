import { Component, Injector, Input } from '@angular/core';
import { SuperSampleButtonComponent } from '../super-sample-button.component';
import {
  DownloadsService,
  PacksService,
  SamplesService,
  StoreService,
  UsersService,
} from '@web/core/services';
import { ESampleFileType, ESampleGeneratedBy } from '@common/enums/common.enum';
import { filter, switchMap, tap, finalize } from 'rxjs/operators';
import { CONSTANTS } from '@web/constants';
import { IBitfApiResponse, IBitfCloseEvent } from '@common/interfaces';
import { EBitfCloseEventStatus } from '@common/enums';
import { of } from 'rxjs';
import { Pack, Store, User } from '@common/core/models';
import { EWebStoreActions } from '@web/enums';
import { bitfPromptToDownloadFileFromUrl } from '@common/libs/bitforce/utils/bitf-files.utils';

@Component({
  selector: 'ss-sample-download-button',
  templateUrl: './sample-download-button.component.html',
  styleUrls: ['./sample-download-button.component.scss'],
  standalone: false,
})
export class SampleDownloadButtonComponent extends SuperSampleButtonComponent {
  @Input() pack?: Pack;
  private updateUser$ = this.usersService.getMe().pipe(
    tap((response: IBitfApiResponse<User>) => {
      this.storeService.setStore(store => {
        store.user = response.content;
      }, EWebStoreActions.UPDATE_USER);
    })
  );
  private get dialogOptions() {
    return {
      width: '500px',
      maxWidth: this.isHandsetLayout ? '95%' : '90%',
      height: 'auto',
      maxHeight: this.isHandsetLayout ? '100%' : '70%',
    };
  }

  constructor(
    protected injector: Injector,
    private downloadsService: DownloadsService,
    private sampleService: SamplesService,
    private usersService: UsersService,
    private packsService: PacksService
  ) {
    super(injector);
  }

  onDownloadSample(fileType = ESampleFileType.WAV) {
    this.loaderService.show();
    this.beforeAction.emit(true);

    this.sampleService
      .downloadSample({ id: this.sample.id, body: { fileType } })
      .pipe(
        tap(result => bitfPromptToDownloadFileFromUrl({ fileUrl: result.content.url })),
        filter(() => {
          // NOTE: if is the first time we download it w'eve to update the user available credits
          // and we've to flag te sample as downloaded
          return !this.sample.isDownloaded;
        }),
        tap(() => {
          this.storeService.setStore(() => {
            // NOTE we modify this object that is the same of the samples list
            this.sample.isDownloaded = true;
          }, EWebStoreActions.UPDATE_SAMPLES_LIST);
        }),
        tap(() => {
          // NOTE: User clicks a fav in the list sample
          // sync the current sample in case is not the same object as that one that is in the list
          // ex you set fav to a sample that is inside a search, but the current sample is not the same
          // object
          // and is set from a package samples list
          if (this.sample.id === this.storeService.store?.currentSample?.id) {
            this.storeService.setStore((store: Store) => {
              store.currentSample.isDownloaded = true;
            }, EWebStoreActions.UPDATE_CURRENT_SAMPLE);
          }
          // NOTE: User clicks a fav in the current sample
          // this is the opposite, we've to sync a list items that could be the same object as the
          // current sample
          const listSample = this.storeService.store.samplesList.find(
            sample => sample?.id === this.storeService.store?.currentSample?.id
          );
          if (listSample) {
            this.storeService.setStore(() => {
              listSample.isDownloaded = true;
            }, EWebStoreActions.UPDATE_SAMPLES_LIST);
          }
        }),
        filter(() => this.sample.generatedBy !== ESampleGeneratedBy.AI),
        switchMap(() => this.updateUser$),
        finalize(() => this.actionCompleted.emit(true))
      )
      .subscribe();
  }

  onDownloadBulk(fileType = ESampleFileType.WAV) {
    let obs;
    if (this.samples.length >= 5) {
      obs = this.dialogsService.dialog
        .open(CONSTANTS.okCancelDialogComponent, {
          ...this.dialogOptions,
          data: {
            title: 'Download samples',
            message: `Are you sure you want to download ${this.samples.length} samples?`,
            cancelText: 'Cancel',
            okText: `Download ${this.samples.length} samples`,
          },
        })
        .afterClosed()
        .pipe(
          filter((result: IBitfCloseEvent<string>) => {
            return result?.status === EBitfCloseEventStatus.OK;
          })
        );
    } else {
      obs = of(true);
    }

    obs
      .pipe(
        switchMap(() => {
          this.loaderService.show();
          this.beforeAction.emit(true);

          return this.downloadsService
            .bulkDownloadSamples({
              body: { ids: this.samples.map(sample => sample.id), fileType },
            })
            .pipe(
              tap(result => {
                this.bulkActionCompleted.emit(true);
                bitfPromptToDownloadFileFromUrl({ fileUrl: result.content.url });
              }),
              filter(() => {
                // NOTE: if is the first time we download it w'eve to update the user available credits
                // and we've to flag te samples as downloaded
                return this.samples.some(sample => !sample.isDownloaded);
              }),
              tap(() => {
                this.storeService.setStore(() => {
                  // NOTE we modify this object that is the same of the samples list
                  this.samples.forEach(sample => (sample.isDownloaded = true));
                }, EWebStoreActions.UPDATE_SAMPLES_LIST);
              }),
              // NOTE: User clicks a fav in the list sample
              // sync the current sample in case is not the same object as that one that is in the list
              // ex you set fav to a sample that is inside a search, but the current sample is not the same
              // object and is set from a package samples list
              tap(() => {
                const currentSample = this.samples.find(
                  sample => sample.id === this.storeService.store?.currentSample?.id
                );
                if (currentSample) {
                  this.storeService.setStore((store: Store) => {
                    store.currentSample.isDownloaded = true;
                  }, EWebStoreActions.UPDATE_CURRENT_SAMPLE);
                }
              }),
              filter(() => this.samples.some(sample => sample.generatedBy !== ESampleGeneratedBy.AI)),
              switchMap(() => this.updateUser$)
            );
        })
      )
      .subscribe();
  }

  onDownloadPack(fileType = ESampleFileType.WAV) {
    this.beforeAction.emit(true);

    this.dialogsService.dialog
      .open(CONSTANTS.okCancelDialogComponent, {
        ...this.dialogOptions,
        data: {
          title: 'Download pack',
          message:
            `Are you sure you want to download this pack: "${this.pack.label}"` +
            ` which contains ${this.pack.numberOfSamples} samples?`,
          cancelText: 'Cancel',
          okText: `Download ${this.pack.numberOfSamples} samples`,
        },
      })
      .afterClosed()
      .pipe(
        filter((result: IBitfCloseEvent<string>) => {
          return result?.status === EBitfCloseEventStatus.OK;
        }),
        switchMap(() => {
          this.loaderService.show();

          return this.packsService.downloadPack({ id: this.pack.id, body: { fileType } }).pipe(
            tap(result => bitfPromptToDownloadFileFromUrl({ fileUrl: result.content.url })),
            tap(() => {
              this.storeService.setStore(store => {
                // NOTE We set all samples as downloaded
                store.samplesList.forEach(sample => (sample.isDownloaded = true));
              }, EWebStoreActions.UPDATE_SAMPLES_LIST);
            }),
            tap(() => this.actionCompleted.emit(true)),
            switchMap(() => this.updateUser$)
          );
        })
      )
      .subscribe();
  }
}
