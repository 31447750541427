import { Component } from '@angular/core';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';

@Component({
  selector: 'ss-desktop-audio-player',
  templateUrl: './desktop-audio-player.component.html',
  styleUrls: ['./desktop-audio-player.component.scss'],
  standalone: false,
})
export class DesktopAudioPlayerComponent extends AudioPlayerComponent {}
