<h2 mat-dialog-title>Add to collection</h2>
<mat-dialog-content>
  <button mat-button
    (click)="onCreateNewCollection()"
    type="button">
    <mat-icon>add</mat-icon>
    Create new collection
  </button>

  <ss-inline-loader class="mt-5"
    *ngIf="isLoading"></ss-inline-loader>

  <div *ngIf="!isLoading && !pinnedCollections.length && !otherCollections.length"
    class="m-3 p-3 text-center">No collections found</div>

  <mat-selection-list *ngIf="pinnedCollections.length || otherCollections.length"
    [multiple]="false"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="onCollectionSelected($event)">
    <div mat-subheader
      *ngIf="pinnedCollections.length">Pinned collections</div>
    <mat-list-option *ngFor="let collection of pinnedCollections"
      [value]="collection">
      <img matListItemIcon
        [src]="collection.coverUrl"
        [alt]="collection.label">
      <div matListItemTitle>{{collection.label}}</div>
      <div matListItemLine>{{collection.numberOfSamples || "0"}} samples</div>
    </mat-list-option>

    <mat-divider *ngIf="pinnedCollections.length"></mat-divider>

    <div mat-subheader
      *ngIf="pinnedCollections.length">Other collections</div>
    <mat-list-option *ngFor="let collection of otherCollections"
      [value]="collection">
      <img matListItemIcon
        [src]="collection.coverUrl"
        [alt]="collection.label">
      <div matListItemTitle>{{collection.label}}</div>
      <div matListItemLine>{{collection.numberOfSamples || "0"}} samples</div>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-divider>
</mat-divider>
<mat-dialog-actions class="justify-content-end">
  <button mat-button
    (click)="onCancel()"
    type="button">
    Cancel
  </button>

  <button mat-flat-button
    [disabled]="!selectedCollection"
    (click)="onOk()"
    class="ml-3">
    Add
  </button>
</mat-dialog-actions>