import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ss-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  standalone: false,
})
export class OnboardingComponent {
  constructor(private router: Router) {}

  onSave() {
    this.router.navigate(['/']);
  }
}
