import { Component, HostBinding, Input } from '@angular/core';
import { Sample } from '@common/core/models';
import { StoreService } from '@common/core/services';

@Component({
  selector: 'ss-header-cover',
  templateUrl: './header-cover.component.html',
  styleUrls: ['./header-cover.component.scss'],
  standalone: false,
})
export class HeaderCoverComponent {
  @Input() coverUrl: string;
  @Input() altImage?: string;
  @Input() sample?: Sample;
  @Input() borderColor;
  @HostBinding('class')
  get hostClass() {
    let classes = '';
    if (this.storeService.store.activeBreakpoints.isWeb) {
      classes += 'mouseover-effect';
    }
    if (this.borderColor) {
      classes += ` border-${this.borderColor}`;
    }
    return classes;
  }

  constructor(private storeService: StoreService) {}
}
