import { Component, Injector, OnInit } from '@angular/core';
import { SearchSamplesSuperFilterComponent } from '../search-samples-super-filter/search-samples-super-filter.component';
import { ESampleKey, ESampleScale, ESampleType } from '@common/enums/common.enum';
import { CONSTANTS } from '@web/constants';
import { StoreService } from '@common/core/services';

@Component({
  selector: 'ss-search-samples-active-tags',
  templateUrl: './search-samples-active-tags.component.html',
  styleUrls: ['./search-samples-active-tags.component.scss'],
  standalone: false,
})
export class SearchSamplesActiveTagsComponent extends SearchSamplesSuperFilterComponent implements OnInit {
  eSampleType = ESampleType;
  eSampleKey = ESampleKey;
  eSampleScale = ESampleScale;
  constants = CONSTANTS;

  get shouldShowTagsBar() {
    return (
      this.filters.promptHistory ||
      this.filters.tags?.length ||
      this.filters.excludedTags?.length ||
      this.shouldShowDetails
    );
  }

  get shouldShowTags() {
    return (
      this.storeService.store.activeBreakpoints.isWebLayout &&
      (this.filters.tags?.length || this.filters.excludedTags?.length)
    );
  }

  get shouldShowDetails() {
    const isTypeSet = this.filters.sampleType !== ESampleType.ALL;
    const isKeySet = this.filters.key !== ESampleKey.ALL;
    const isScaleSet = this.filters.scale !== ESampleScale.ALL;
    const isBpmSet =
      this.filters.minBpm !== CONSTANTS.BPM_MIN_DEFAULT_VALUE ||
      this.filters.maxBpm !== CONSTANTS.BPM_MAX_DEFAULT_VALUE;
    return (
      this.storeService.store.activeBreakpoints.isWebLayout &&
      (isTypeSet || isKeySet || isScaleSet || isBpmSet)
    );
  }

  constructor(protected injector: Injector, private storeService: StoreService) {
    super(injector);
  }

  onRemovePrompt() {
    this.filters.prompt = '';
    this.filters.promptHistory = '';
    this.updateFilters();
  }

  onTagChange() {
    this.updateFilters();
  }

  onRemoveBpm() {
    this.filters.minBpm = CONSTANTS.BPM_MIN_DEFAULT_VALUE;
    this.filters.maxBpm = CONSTANTS.BPM_MAX_DEFAULT_VALUE;
    this.updateFilters();
  }

  onRemoveSampleType() {
    this.filters.sampleType = ESampleType.ALL;
    this.updateFilters();
  }

  onRemoveKey() {
    this.filters.key = ESampleKey.ALL;
    this.updateFilters();
  }

  onRemoveScale() {
    this.filters.scale = ESampleScale.ALL;
    this.updateFilters();
  }
}
