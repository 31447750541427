import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Genre, UserSettings } from '@common/core/models';
import { LoaderService, StoreService, UsersService } from '@common/core/services';
import { GenresService } from '@common/core/services/api/genres.service';
import { EBitfApiSortDirection } from '@common/enums';
import { forkJoin } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'ss-preferred-genres',
  templateUrl: './preferred-genres.component.html',
  styleUrls: ['./preferred-genres.component.scss'],
  standalone: false,
})
export class PreferredGenresComponent implements OnInit {
  @Output() save = new EventEmitter<void>();
  genres: Genre[] = [];
  selectedGenres = new Set<string>();
  minGenres = 3;
  userSettings: UserSettings;

  get isHandsetLayout() {
    return this.storeService.store.activeBreakpoints.isHandsetLayout;
  }

  constructor(
    private genresService: GenresService,
    private usersService: UsersService,
    private storeService: StoreService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.selectedGenres = new Set<string>();
    this.loaderService.show();

    forkJoin([
      this.genresService.search<Genre>({
        page: 1,
        size: Number.MAX_SAFE_INTEGER,
        sorting: [{ property: 'label', direction: EBitfApiSortDirection.ASC }],
      }),
      this.usersService.getRel<UserSettings>({
        id: this.storeService.store.user.id,
        relation: 'settings',
      }),
    ])
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(([genres, userSettings]) => {
        this.genres = genres.content;
        this.userSettings = userSettings.content;
        this.selectedGenres = new Set<string>(userSettings.content.favoriteGenres?.map(x => x.id));
      });
  }

  onClick(genre: Genre) {
    if (this.selectedGenres.has(genre.id)) {
      this.selectedGenres.delete(genre.id);
    } else {
      this.selectedGenres.add(genre.id);
    }
  }

  onSave() {
    if (this.selectedGenres.size < this.minGenres) {
      return;
    }
    this.userSettings.favoriteGenres = this.genres.filter(x => this.selectedGenres.has(x.id));
    this.loaderService.show();
    this.usersService
      .patchRel<UserSettings>({
        path: '/settings',
        id: this.storeService.store.user.id,
        body: this.userSettings,
      })
      .pipe(
        tap(() => this.save.emit()),
        finalize(() => this.loaderService.hide())
      )
      .subscribe();
  }

  trackByFn(_index: number, item: Genre) {
    return item.id;
  }
}
