import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter, Tag } from '@common/core/models';

@Component({
  selector: 'ss-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: false,
})
export class TagComponent {
  @Input() tag?: Tag;
  @Input() label?: string;
  @Input() filters?: Filter;
  @Input() showDeleteButton = false;
  @Input() cssClasses?: string;
  @Input() isSelected = false;
  @Input() isExcluded = false;
  @Input() disableHighlight = false;
  @Input() isDisabled = false;
  @Output() tagClick = new EventEmitter();
  @Output() tagRemove = new EventEmitter();
  @Output() change = new EventEmitter();

  get amISelected(): boolean {
    return this.isSelected || !!this.filters?.tags.find(t => t.id === this.tag.id);
  }

  get amIExcluded(): boolean {
    return this.isExcluded || !!this.filters?.excludedTags.find(t => t.id === this.tag.id);
  }

  get amIHighlighted(): boolean {
    return this.amISelected || this.amIExcluded;
  }

  onTagClick($event) {
    $event.stopPropagation();
    if (this.isDisabled) {
      return;
    }
    if (this.tag) {
      // NOTE: optimistic changes we mutate the part straight away
      const activeTagIndex = this.filters.tags.findIndex(t => t.id === this.tag.id);
      if (activeTagIndex >= 0) {
        // NOTE: must be moved in the excluded tags
        this.filters.tags.splice(activeTagIndex, 1);
        this.filters.excludedTags.push(this.tag);
      } else {
        const excludedTagIndex = this.filters.excludedTags.findIndex(t => t.id === this.tag.id);
        if (excludedTagIndex >= 0) {
          // NOTE: must be moved in the active tags
          this.filters.excludedTags.splice(excludedTagIndex, 1);
          this.filters.tags.push(this.tag);
        } else {
          // NOTE: we activate the tag
          this.filters.tags.push(this.tag);
        }
      }
    }
    this.tagClick.emit();
    this.change.emit();
  }

  onTagRemove($event) {
    $event.stopPropagation();
    if (this.tag) {
      // NOTE: optimistic changes we mutate the part straight away
      const activeTagIndex = this.filters.tags.findIndex(t => t.id === this.tag.id);
      if (activeTagIndex >= 0) {
        this.filters.tags.splice(activeTagIndex, 1);
      } else {
        const excludedTagIndex = this.filters.excludedTags.findIndex(t => t.id === this.tag.id);
        if (excludedTagIndex >= 0) {
          this.filters.excludedTags.splice(excludedTagIndex, 1);
        }
      }
    }
    this.tagRemove.emit();
    this.change.emit();
  }
}
