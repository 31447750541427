import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService, StoreService } from '@web/core/services';
import { EBitfAuthState } from '@web/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ss-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: false,
})
export class SignInComponent implements OnInit, OnDestroy {
  form: FormGroup;
  showSignInForm = false;
  hidePassword = true;
  subscriptions = new Subscription();
  authState: EBitfAuthState = EBitfAuthState.TO_BE_LOGGED;
  eBitfAuthState = EBitfAuthState;

  get showSSO() {
    return this.isLoading && this.activatedRoute.snapshot?.queryParams?.customer_id;
  }

  get showSignInSignUp() {
    return !this.isLoading && !this.showSSO && !this.showSignInForm;
  }

  get isLoading() {
    return (
      this.authState === EBitfAuthState.LOGIN_IN_PROGRESS ||
      this.authState === EBitfAuthState.TOKEN_RETRIEVED ||
      this.authState === EBitfAuthState.LOGGED
    );
  }

  constructor(
    public storeService: StoreService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initForm();
    this.subscriptions.add(
      this.authService.listenForAuthStateChange().subscribe((authState: EBitfAuthState) => {
        this.authState = authState;
      })
    );
    this.checkForShopifySSO();
    this.checkForLocalSignIn();
  }

  onLocalhostSignIn() {
    const requestParams = {
      body: this.form.value,
    };
    this.authService.handleAuthentication(requestParams);
  }

  onSignUp() {
    window.open('https://www.samplesoundmusic.com/pages/app-register', '_self');
  }

  onSignIn() {
    window.open('https://www.samplesoundmusic.com/pages/app-login', '_self');
  }

  private checkForShopifySSO() {
    this.authService.handleShopifySSOAuthentication(this.activatedRoute.snapshot.queryParams);
  }

  private checkForLocalSignIn() {
    this.showSignInForm = this.activatedRoute.snapshot.queryParams['local-sign-in'] === 'true';
  }

  private initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
