<!-- In table -->

<ng-container *ngIf="!isBulk && sample">
  <button *ngIf="!showLabel"
    mat-icon-button
    [matTooltip]="sample.isDownloaded? 'You already own this sample, Re-downloading it won\'t deduct any points from your account' : 'Download'"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    (click)="onDownloadSample()">
    <mat-icon [ngClass]="sample.isDownloaded? 'tertiary-color-base' : ''">file_download</mat-icon>
  </button>

  <button *ngIf="showLabel"
    mat-button
    [matTooltip]="sample.isDownloaded? 'You already own this sample, Re-downloading it won\'t deduct any points from your account' : 'Download'"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    (click)="onDownloadSample()">
    <mat-icon [ngClass]="sample.isDownloaded? 'tertiary-color-base' : ''">file_download</mat-icon>
    Download
  </button>
</ng-container>


<!-- In Bulk -->
<button mat-button
  *ngIf="isBulk"
  class="bulk-action-button"
  (click)="onDownloadBulk()">
  <mat-icon>file_download</mat-icon>
  Download
</button>

<!-- In pack -->
<button mat-mini-fab
  *ngIf="pack"
  class="btn-secondary"
  (click)="onDownloadPack()">
  <mat-icon>file_download</mat-icon>
</button>

<!-- <mat-menu #menu="matMenu">
  <button mat-menu-item
    (click)="onDownload(eSampleFileType.FLAC)">Flac</button>
  <button mat-menu-item
    (click)="onDownload(eSampleFileType.WAV)">Waw</button>
</mat-menu> -->