<div class="px-4 pt-3">

  <div class="d-flex justify-content-between align-items-center text-center">
    <h3 mat-dialog-title
      class="px-0">{{this.dialogData.title}}</h3>
    <button mat-icon-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
</div>

<ng-container *ngIf="!this.dialogData.isEditMode; else generateVariation">
  <mat-dialog-content>
    <div class="prompt-container p-3">
      <label>{{this.dialogData.sample.aiGeneration.prompt}}</label>
    </div>
    <mat-dialog-actions class="d-flex justify-content-end mt-1 p-0">
      <button mat-flat-button
        class="ml-1 btn-tertiary"
        (click)="onCopyPrompt(this.dialogData.sample.aiGeneration.prompt)">
        <mat-icon>content_copy</mat-icon> Copy Prompt
      </button>
    </mat-dialog-actions>

    <ng-container *ngIf="this.dialogData.sample.aiGeneration.variationAmount >= 0">
      <h3>Conditioning Prompt</h3>
      <div class="prompt-container p-3">
        {{this.dialogData.sample.aiGeneration.conditioningPrompt}}
      </div>
      <mat-dialog-actions class="d-flex justify-content-end mt-1 p-0">
        <button mat-flat-button
          class="ml-1 btn-tertiary"
          (click)="onCopyPrompt(this.dialogData.sample.aiGeneration.conditioningPrompt)">
          <mat-icon>content_copy</mat-icon> Copy Prompt
        </button>
      </mat-dialog-actions>

      <div class="d-flex align-items-center justify-content-between">
        <span class="mat-label-small mr-1">Variation Amount:</span>
        <div class="flex-grow-1 text-center">
          <mat-slider class="w-100 custom-slider"
            [min]="0"
            [max]="100"
            [disabled]="true">
            <input matSliderThumb
              [ngModel]="this.dialogData.sample.aiGeneration.variationAmount"
              #variationAmountValue>
          </mat-slider>

        </div>
        <div class="mat-label-small ml-3"><span>{{variationAmountValue.value}} %</span></div>
      </div>
    </ng-container>

  </mat-dialog-content>
</ng-container>

<mat-dialog-actions class="d-flex justify-content-end px-4 pb-3 pt-0"
  *ngIf="this.dialogData.isEditMode">
  <button mat-flat-button
    class="ml-1 btn-tertiary"
    (click)="onGenerateVariation()">Generate Variation</button>
</mat-dialog-actions>

<ng-template #generateVariation>
  <mat-dialog-content>
    <form *ngIf="form"
      [formGroup]="form">
      <h3 class="mt-0">Conditioning Prompt</h3>
      <p>Influence the generation into a particular direction by specifying anything from instruments,
        genres,
        or even scenarios.</p>
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>Insert here your conditioning prompt</mat-label>
        <textarea matInput
          formControlName="conditioningPrompt"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>

      <div class="d-flex align-items-center justify-content-between">
        <span class="mat-label-small mr-1">Variation Amount:</span>

        <div class="flex-grow-1 text-center p-3">
          <mat-slider class="w-100 custom-slider"
            [min]="0"
            [max]="100">
            <input matSliderThumb
              formControlName="variationAmount"
              #variationAmountValue>
          </mat-slider>

        </div>
        <div class="mat-label-small ml-3"><span>{{variationAmountValue.value}} %</span></div>
      </div>
    </form>

  </mat-dialog-content>
</ng-template>