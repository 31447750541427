import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IBitfCloseEvent } from '@interfaces';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@enums';
import { Collection, Sample } from '@common/core/models';
import { CollectionsService, LoaderService, StoreService, ToastMessagesService } from '@web/core/services';
import { switchMap, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { CONSTANTS } from '@web/constants';

@Component({
  selector: 'ss-create-update-collection-dialog',
  templateUrl: './create-update-collection-dialog.component.html',
  styleUrls: ['./create-update-collection-dialog.component.scss'],
  standalone: false,
})
export class CreateUpdateCollectionDialogComponent implements OnInit {
  form: UntypedFormGroup;
  isInCreateMode: boolean;
  PIN_COLORS = CONSTANTS.PIN_COLORS;
  selectedColor: string;

  constructor(
    public dialogRef: MatDialogRef<CreateUpdateCollectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { sample?: Sample; samples?: Sample[]; collection?: Collection },
    private collectionsService: CollectionsService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private toastMessagesService: ToastMessagesService,
    private storeService: StoreService
  ) {
    this.isInCreateMode = !this.dialogData?.collection?.id;
  }

  ngOnInit() {
    this.initForm();
  }

  onOk() {
    this.loaderService.show();
    const newCollection = new Collection({ ...this.form.value, pinColor: this.selectedColor });

    this.collectionsService[this.isInCreateMode ? 'post' : 'patch']<Collection>({
      body: newCollection,
    })
      .pipe(
        switchMap(response => {
          return this.handleAddSamplesToCollection(response.content);
        }),
        tap(() => {
          // NOTE: because we are creating or editing a collection we need to update the pinned collections
          this.storeService.setPinnedCollections();
          this.dialogRef.close({
            status: EBitfCloseEventStatus.OK,
          } as IBitfCloseEvent<void>);
        })
      )
      .subscribe();
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }

  private handleAddSamplesToCollection(collection: Collection) {
    const { sample, samples } = this.dialogData;
    if (sample) {
      return this.collectionsService
        .linkRel<any>({
          id: collection.id,
          relation: 'samples',
          relationId: sample.id,
        })
        .pipe(
          tap(() => {
            this.toastMessagesService.show({
              type: EBitfUiMessageType.SUCCESS,
              title: `Sample added to collection: ${collection.label}`,
            });
          })
        );
    } else if (samples?.length) {
      return this.collectionsService
        .linkRel<any>({
          id: collection.id,
          relation: 'samples',
          relationId: 'bulk',
          body: { ids: samples.map(sample => sample.id) },
        })
        .pipe(
          tap(() => {
            this.toastMessagesService.show({
              type: EBitfUiMessageType.SUCCESS,
              title: `Samples added to collection: ${collection.label}`,
            });
          })
        );
    }
    return of(true);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: [this.dialogData?.collection?.id],
      label: [this.dialogData?.collection?.label, Validators.required],
      description: [this.dialogData?.collection?.description],
      isPinned: [this.dialogData?.collection?.isPinned],
    });
    if (this.dialogData?.collection?.pinColor) {
      this.selectedColor = this.dialogData?.collection?.pinColor;
    }
  }
}
