import { Component, Injector, Input } from '@angular/core';
import { AiGenerate, Sample } from '@common/core/models';
import { AiService } from '@common/core/services/api/ai.service';
import { tap } from 'rxjs/operators';
import { SuperSampleButtonComponent } from '../super-sample-button.component';
import { ToastMessagesService } from '@web/core/services';
import { EBitfUiMessageType } from '@common/enums';

@Component({
  selector: 'ss-sample-regenerate-sound-button',
  templateUrl: './sample-regenerate-sound-button.component.html',
  styleUrls: ['./sample-regenerate-sound-button.component.scss'],
  standalone: false,
})
export class SampleRegenerateSoundButtonComponent extends SuperSampleButtonComponent {
  constructor(
    protected injector: Injector,
    private aiService: AiService,
    private toastMessagesService: ToastMessagesService
  ) {
    super(injector);
  }

  onAiGenerate() {
    if (this.storeService.store.aiGenerationState.isJobInProgress) {
      this.toastMessagesService.show({
        title: '',
        message: 'Sample generation already in progress, please wait',
        type: EBitfUiMessageType.SUCCESS,
      });
      return;
    }
    this.aiService
      .action<AiGenerate>({
        action: 'generate',
        body: {
          aiModelId: this.sample?.aiGeneration?.aiModelId,
          prompt: this.sample?.aiGeneration.prompt,
        },
        modelMapper: 'ai-generate',
      })
      .pipe(
        tap(response => {
          this.toastMessagesService.show({
            title: '',
            message: 'Regenerate in progress',
            type: EBitfUiMessageType.SUCCESS,
          });
        })
      )
      .subscribe();
  }
}
