import { Component } from '@angular/core';
import { SuperSampleButtonComponent } from '../super-sample-button.component';

@Component({
  selector: 'ss-sample-buy-button',
  templateUrl: './sample-buy-button.component.html',
  styleUrls: ['./sample-buy-button.component.scss'],
  standalone: false,
})
export class SampleBuyButtonComponent extends SuperSampleButtonComponent {
  onBuy() {
    if (this.isBulk) {
      // NOTE: maybe with the bulk we've to add a confirm first
      // NOTE we remove samples that are already bought
      const buyableSamples = this.samples.filter(sample => !sample.isBought);
      console.log('Bulk buy', buyableSamples);
    } else {
      console.log('buy', this.sample);
    }
  }
}
