import { Component, Injector } from '@angular/core';
import { SuperSampleButtonComponent } from '../super-sample-button.component';
import { ApiCallStateService, CollectionsService, ToastMessagesService } from '@web/core/services';

import { IBitfCloseEvent } from '@common/interfaces';
import { EDialogResponseDataType } from '@common/enums/common.enum';
import { tap } from 'rxjs/operators';
import { SampleAddOrCreateCollectionDialogComponent } from '@web/shared/sample-add-or-create-collection-dialog/sample-add-or-create-collection-dialog.component';
import { CreateUpdateCollectionDialogComponent } from '@web/shared/create-update-collection-dialog/create-update-collection-dialog.component';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { ESignal } from '@web/enums';

@Component({
  selector: 'ss-sample-collection-button',
  templateUrl: './sample-collection-button.component.html',
  styleUrls: ['./sample-collection-button.component.scss'],
  standalone: false,
})
export class SampleCollectionButtonComponent extends SuperSampleButtonComponent {
  protected collectionsService: CollectionsService;
  protected toastMessagesService: ToastMessagesService;
  protected apiCallStateService: ApiCallStateService;
  constructor(protected injector: Injector) {
    super(injector);
    this.collectionsService = this.injector.get(CollectionsService);
    this.toastMessagesService = this.injector.get(ToastMessagesService);
    this.apiCallStateService = this.injector.get(ApiCallStateService);
  }
  onAddToCollection() {
    this.beforeAction.emit(true);

    this.dialogsService.dialog
      .open(SampleAddOrCreateCollectionDialogComponent, {
        width: '600px',
        maxWidth: this.isHandsetLayout ? '95%' : '90%',
        height: 'auto',
        maxHeight: this.isHandsetLayout ? '100%' : '70%',
        data: {
          sample: this.sample,
          samples: this.samples,
          collection: this.collection,
        },
      })
      .afterClosed()
      .subscribe((result: IBitfCloseEvent<string>) => {
        if (result?.status === EBitfCloseEventStatus.OK) {
          if (result?.data === EDialogResponseDataType.OPEN_CREATE_COLLECTION_DIALOG) {
            // NOTE: The user wants to create a new collection.
            this.openCreateCollectionDialog();
          } else {
            //NOTE: The user added sample(s) to the collection.
            if (this.isBulk) {
              this.bulkActionCompleted.emit(true);
            } else {
              this.actionCompleted.emit(true);
            }
          }
        }
      });
  }

  onRemoveFromCollection() {
    this.beforeAction.emit(true);

    if (this.isBulk) {
      this.collectionsService
        .unLinkRel<any>({
          id: this.collection.id,
          relation: 'samples',
          relationId: 'bulk',
          body: { ids: this.samples.map(sample => sample.id) },
        })
        .pipe(
          tap(() => {
            this.toastMessagesService.show({
              type: EBitfUiMessageType.SUCCESS,
              title: `Samples removed from collection: ${this.collection.label}`,
            });
            this.bulkActionCompleted.emit(true);
            this.appSessionService.signal$.next({
              action: ESignal.SAMPLE_REMOVED_FROM_COLLECTION,
              payload: { numberOfDeletedSamples: this.samples.length },
            });
          })
        )
        .subscribe();
    } else {
      this.collectionsService
        .unLinkRel<any>({ id: this.collection.id, relation: 'samples', relationId: this.sample.id })
        .pipe(
          tap(() => {
            this.toastMessagesService.show({
              type: EBitfUiMessageType.SUCCESS,
              title: `Sample removed from collection: ${this.collection.label}`,
            });
            this.appSessionService.signal$.next({
              action: ESignal.SAMPLE_REMOVED_FROM_COLLECTION,
              payload: { numberOfDeletedSamples: 1 },
            });
          })
        )
        .subscribe();
    }
  }

  private openCreateCollectionDialog() {
    this.dialogsService.dialog
      .open(CreateUpdateCollectionDialogComponent, {
        width: '600px',
        maxWidth: this.isHandsetLayout ? '95%' : '90%',
        height: 'auto',
        maxHeight: this.isHandsetLayout ? '100%' : '70%',
        data: {
          sample: this.sample,
          samples: this.samples,
        },
      })
      .afterClosed()
      .subscribe((result: IBitfCloseEvent<string>) => {
        if (result?.status === EBitfCloseEventStatus.OK) {
          if (this.isBulk) {
            this.bulkActionCompleted.emit(true);
          } else {
            this.actionCompleted.emit(true);
          }
        }
      });
  }
}
