import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ESampleKey } from '@common/enums/common.enum';

@Component({
  selector: 'ss-search-samples-key-button',
  templateUrl: './search-samples-key-button.component.html',
  styleUrls: ['./search-samples-key-button.component.scss'],
  standalone: false,
})
export class SearchSamplesKeyButtonComponent {
  @Input() label;
  @Input() svg;
  @Input() value: ESampleKey;
  @Input() set selectedFilterValue(value: ESampleKey) {
    this.isSelected = value === this.value;
  }

  @Output() selected: EventEmitter<ESampleKey> = new EventEmitter<ESampleKey>();

  isSelected: boolean;

  onClick($event) {
    $event.stopPropagation();
    this.selected.emit(this.value);
  }
}
