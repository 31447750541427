import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Filter } from '@common/core/models';
import { ESampleKey, ESampleScale } from '@common/enums/common.enum';

@Component({
  selector: 'ss-search-samples-key',
  templateUrl: './search-samples-key.component.html',
  styleUrls: ['./search-samples-key.component.scss'],
  standalone: false,
})
export class SearchSamplesKeyComponent {
  @Input() filters: Filter;
  @Input() isLoading = false;
  @Output() change = new EventEmitter();
  @Output() closeMenu = new EventEmitter();
  eSampleKey = ESampleKey;
  eSampleScale = ESampleScale;

  onPanelClick($event) {
    $event.stopPropagation();
  }

  onClose() {
    this.closeMenu.emit();
  }

  onKeyChange(key: ESampleKey) {
    this.filters.key = key;
    this.change.emit();
  }

  onScaleChange(event: MatButtonToggleChange) {
    this.filters.scale = event.value;
    this.change.emit();
  }

  onResetScaleAndKey() {
    this.filters.key = ESampleKey.ALL;
    this.filters.scale = ESampleScale.ALL;
    this.change.emit();
    this.closeMenu.emit();
  }
}
