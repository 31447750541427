import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Filter, Tag } from '@common/core/models';
import { ApiCallStateService } from '@common/core/services';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';
import { FilterApiRequestPart } from '@web/core/api-call-state/filter-api-request-part.builder';
import { AppSessionService } from '@web/core/services';
import { EApiCallStateNames, EApiRequestPartKeys } from '@web/enums';
import { IBitfApiRequest, IBitfApiResponse } from '@web/interfaces';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ss-search-samples-super-filter',
  templateUrl: './search-samples-super-filter.component.html',
  styleUrls: ['./search-samples-super-filter.component.scss'],
  standalone: false,
})
export abstract class SearchSamplesSuperFilterComponent implements OnInit, OnDestroy {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() service: {
    getFilters: (requestParams?: IBitfApiRequest) => Observable<IBitfApiResponse<Filter>>;
    getAutocomplete: (requestParams: IBitfApiRequest) => Observable<IBitfApiResponse<Tag[]>>;
  };

  private route: ActivatedRoute;
  protected paginationRequestPart: BitfApiRequestPart;
  protected apiCallStateService: ApiCallStateService;
  protected resourceId: string;
  protected subscriptions = new Subscription();
  public filtersRequestPart: FilterApiRequestPart;
  public isLoading = false;
  public appSessionService: AppSessionService;

  get filters(): Filter {
    return this.filtersRequestPart?.data;
  }

  constructor(protected injector: Injector) {
    this.apiCallStateService = injector.get(ApiCallStateService);
    this.route = injector.get(ActivatedRoute);
    this.appSessionService = injector.get(AppSessionService);
  }

  ngOnInit(): void {
    this.initParts();

    // NOTE if we are inside a favorite, downloads, pack or collection
    // we've the id in the url ad we can load the content
    // otherwise if we are inside the search page the user has to click search
    const childrenActivatedRoute: ActivatedRoute = this.getRouteChildren(this.route);
    this.resourceId = childrenActivatedRoute.snapshot.params.id;
    this.subscriptions.add(
      childrenActivatedRoute.params.subscribe(params => {
        this.resourceId = params.id;
      })
    );
  }

  private getRouteChildren(route: ActivatedRoute): ActivatedRoute {
    return route.firstChild ? this.getRouteChildren(route.firstChild) : route;
  }

  onResetFilters() {
    this.filtersRequestPart.reset();
    this.updateFilters();
  }

  updateFilters() {
    this.paginationRequestPart.reset();
    this.apiCallStateService.setStore(() => {}, this.apiCallStateName);
    this.getFilters();
  }

  protected getFilters() {
    this.isLoading = true;
    // NOTE: we read filters from the part because are needed to get new facets
    const { query } = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    const request: IBitfApiRequest = { query };

    // NOTE: in case we are inside a pack or collection we've to include the id
    if (this.resourceId) {
      request.id = this.resourceId;
    }

    this.service
      .getFilters(request)
      .pipe(
        tap(response => {
          // NOTE update the filters UI with the server response
          // which can disable some filters
          // in this case we don't want to trigger a new search with the .setStore
          this.filtersRequestPart.data = response.content;
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private initParts() {
    this.filtersRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.FILTERS
    ) as FilterApiRequestPart;

    this.paginationRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.PAGINATION
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
