<div class="mx-n3 mx-sm-0">
  <mat-divider class="d-none d-md-block"></mat-divider>
  <mat-toolbar *ngIf="storeService.store.activeBreakpoints.isWebLayout">
    <div class="row justify-content-between align-items-center flex-grow-1"
      *ngIf="selection.isEmpty()">
      <div class="d-flex align-items-center">
        Results
        <ss-inline-loader *ngIf="isLoading"
          class="ml-3"
          [diameter]="20"></ss-inline-loader>
        <div *ngIf="isLoadingMessage"
          class="mat-body-medium inline-block mb-0 ml-3">{{isLoadingMessage}}</div>
        <button *ngIf="shouldShowRefreshListButton"
          mat-button
          class="ml-3"
          (click)="refreshList.emit()"><mat-icon>refresh</mat-icon>Refresh samples</button>
      </div>

      <div class="row"
        *ngIf="showSorting">
        <ss-search-samples-sorting [apiCallStateName]="apiCallStateName"></ss-search-samples-sorting>
      </div>
    </div>

    <div *ngIf="!selection.isEmpty()"
      class="row">
      <ss-sample-download-button [samples]="selection.selected"
        (bulkActionCompleted)="onBulkActionCompleted()"></ss-sample-download-button>

      <ss-sample-favorite-button [samples]="selection.selected"
        [shouldShowAddToFavorite]="shouldShowAddToFavorite"
        (bulkActionCompleted)="onBulkActionCompleted()"></ss-sample-favorite-button>

      <ss-sample-collection-button [samples]="selection.selected"
        [collection]="collection"
        (bulkActionCompleted)="onBulkActionCompleted()"></ss-sample-collection-button>

      <ss-sample-feedback-button [samples]="selection.selected"
        *ngIf="shouldShowAiFeedback"
        (bulkActionCompleted)="onBulkActionCompleted()"></ss-sample-feedback-button>
    </div>
  </mat-toolbar>

  <mat-divider></mat-divider>

  <table mat-table
    [dataSource]="samples"
    [ngClass]="{'table_mobile': isHandsetLayout}"
    class="mat-elevation-z8 table">

    <ng-container matColumnDef="bulk">
      <th mat-header-cell
        *matHeaderCellDef
        class="bulk-column">
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell
        *matCellDef="let row"
        class="bulk-column">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="cover">
      <th mat-header-cell
        *matHeaderCellDef
        class="cover-column"></th>
      <td mat-cell
        *matCellDef="let element"
        class="cover-column">
        <ss-sample-cover [sample]="element"
          [imageWidth]="isHandsetLayout ? 56 : 40"
          [size]="isHandsetLayout ? '56px' : '40px'"></ss-sample-cover>
      </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
      <th mat-header-cell
        *matHeaderCellDef
        class="filename-column">File Name</th>
      <td mat-cell
        *matCellDef="let element"
        class="filename-column">
        <ss-sample-title-and-tags [sample]="element"></ss-sample-title-and-tags>
      </td>
    </ng-container>

    <ng-container matColumnDef="sample">
      <th mat-header-cell
        *matHeaderCellDef
        class="sample-column">Time</th>
      <td mat-cell
        *matCellDef="let element"
        class="sample-column pl-md-0">
        <div class="d-flex align-items-center">
          <bitf-in-view #isInView="isInView"
            [options]="{rootMargin:'0px 0px 1000px 0px'}">
          </bitf-in-view>
          <ss-audio-button *ngIf="!isHandsetLayout"
            [sample]="element"
            class="mr-1"></ss-audio-button>
          <ss-audio-wave [sample]="element"
            class="flex-grow-1"
            *ngIf="isInView.isInView"></ss-audio-wave>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell
        class="key-column"
        *matHeaderCellDef>Key</th>
      <td mat-cell
        *matCellDef="let element"
        class="key-column mat-label-small">{{element.key}}</td>
    </ng-container>

    <ng-container matColumnDef="bpm">
      <th mat-header-cell
        class="bpm-column"
        *matHeaderCellDef>Bpm</th>
      <td mat-cell
        *matCellDef="let element"
        class="bpm-column mat-label-small">{{element.bpm}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
        *matHeaderCellDef
        class="actions-column"></th>
      <td mat-cell
        *matCellDef="let element"
        class="actions-column">
        <div class="d-flex align-items-center justify-content-end">
          <ss-audio-button *ngIf="isHandsetLayout"
            [sample]="element"></ss-audio-button>

          <ng-container>
            <ss-sample-feedback-button class="action-mobile-hidden"
              [sample]="element"
              *ngIf="element?.generatedBy === eSampleGeneratedBy.AI"></ss-sample-feedback-button>

            <ss-sample-favorite-button class="action-mobile-hidden"
              [sample]="element"
              [shouldShowAddToFavorite]="shouldShowAddToFavorite"></ss-sample-favorite-button>

            <ss-sample-download-button class="action-mobile-hidden"
              [sample]="element"></ss-sample-download-button>

            <ss-sample-menu-button [sample]="element"
              [mobileMenuBottomSheetConfig]="mobileMenuBottomSheetConfig"
              [shouldShowViewPack]="shouldShowViewPack"
              [collection]="collection"></ss-sample-menu-button>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <bitf-mat-paginator *ngIf="storeService.store?.user.hasActiveSubscription"
    [apiCallStateName]="apiCallStateName"
    [pageIndexOffset]="1"
    class="paginator"
    [pagination]="pagination"
    [pageSizeOptions]="[20, 50, 100, 200]"
    [options]="paginatorOptions"></bitf-mat-paginator>
</div>