<div class="d-flex h-100 align-items-center">
  <!-- left col -->
  <div class="col-6 d-flex flex-nowrap align-items-center p-0">
    <ss-sample-cover [sample]="sample"
      class="col-auto pl-0"></ss-sample-cover>
    <ss-sample-title-and-tags class="trick-min-width-0"
      [sample]="sample"></ss-sample-title-and-tags>
  </div>

  <!-- center col -->
  <div class="col-12 p-0">
    <div *ngIf="sample"
      class="d-flex flex-nowrap align-items-center">
      <!-- Buttons -->
      <div class="d-flex">
        <button mat-icon-button
          data-testid="previous-sample-button"
          *ngIf="showPreviousSampleButton"
          [disabled]="!sample || isLoading"
          (click)="onPreviousSample($event)">
          <mat-icon class="material-icons-outlined">skip_previous</mat-icon>
        </button>
        <button mat-icon-button
          data-testid="next-sample-button"
          *ngIf="showNextSampleButton"
          [disabled]="!sample || isLoading"
          (click)="onNextSample($event)">
          <mat-icon class="material-icons-outlined">skip_next</mat-icon>
        </button>
        <button mat-icon-button
          data-testid="play-button"
          *ngIf="!isPlaying"
          [disabled]="!sample || isLoading"
          (click)="onPlaySample($event)">
          <mat-icon class="material-icons-outlined">play_arrow</mat-icon>
        </button>
        <button mat-icon-button
          data-testid="pause-button"
          *ngIf="isPlaying"
          [disabled]="!sample || isLoading"
          (click)="onPauseSample($event)">
          <mat-icon class="material-icons-outlined">pause</mat-icon>
        </button>
        <button mat-icon-button
          *ngIf="showLoopButton"
          [disabled]="!sample || isLoading"
          [class]="isLooping? 'btn-tertiary' :''"
          (click)="onToggleLoop($event)">
          <mat-icon class="material-icons-outlined">repeat</mat-icon>
        </button>
      </div>

      <!-- Player -->
      <div class="flex-grow-1">
        <mat-progress-bar *ngIf="isLoading"
          color="primary"
          mode="buffer"></mat-progress-bar>

        <div class="flex-grow-1"
          [hidden]="isLoading"
          #waveform></div>
      </div>

      <!-- Timer -->
      <div class="col-auto mat-label-small">
        <!-- <span *ngIf="currentTime">{{currentTimeAsMilliseconds | date:'mm:ss'}}s&nbsp;</span> -->
        <span *ngIf="duration">{{durationAsMilliseconds | date:'mm:ss'}}&nbsp;</span>
      </div>

      <!-- Volume -->
      <!-- {{volume}} -->
      <ss-sample-volume (toggleVolume)="onToggleVolume($event)"
        [(volume)]="volume"
        [sample]="sample"
        [isLoading]="isLoading"></ss-sample-volume>
    </div>
  </div>

  <!-- right col -->
  <div class="col-6 d-flex justify-content-between align-items-center">
    <!-- Sample infos -->
    <div class="mat-label-small">
      <span *ngIf="sample?.key">Key: {{sample.key}}</span>&nbsp;
      <span *ngIf="sample?.bpm">Bpm: {{sample.bpm}}</span>
    </div>

    <!-- Actions -->
    <div class="d-flex"
      *ngIf="sample">
      <ss-sample-favorite-button [sample]="sample"
        *ngIf="!sample?.isPackSample"></ss-sample-favorite-button>
      <ss-sample-download-button [sample]="sample"></ss-sample-download-button>
      <ss-sample-menu-button *ngIf="!sample?.isPackSample"
        [sample]="sample"></ss-sample-menu-button>
    </div>
  </div>
</div>