import { SidenavLink } from '@common/interfaces/common.interface';
import { BITF_VALIDATION_MESSAGES } from '.';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: ' | Sample sound',
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [
    { code: 'en', label: 'English' },
    // { code: 'it', label: 'Italiano' },
  ],
  DEFAULT_LANGUAGE: { code: 'en', label: 'English' },
  LOCAL_STORAGE_VERSION: 1,
  VALIDATION_MESSAGES: {
    ...BITF_VALIDATION_MESSAGES,
  },
  PIN_COLORS: ['green', 'red', 'yellow', 'cyan', 'orange', 'blue'],
  SIDENAV_MENU_LINKS: [
    { label: 'Home', icon: 'home', url: '/', aiBadge: false },
    { label: 'Search', icon: 'search', url: '/samples/search', aiBadge: false },
    { label: 'Generation', icon: 'graphic_eq', url: '/ai/generate', aiBadge: true },
    { label: 'Download', icon: 'download', url: '/downloads/search', aiBadge: false },
    { label: 'Favorites', icon: 'favorite', url: '/favorites/search', aiBadge: false },
    { label: 'Collections', icon: 'library_music', url: '/collections/search', aiBadge: false },
  ] as SidenavLink[],

  BPM_MIN_DEFAULT_VALUE: 40,
  BPM_MAX_DEFAULT_VALUE: 240,
  BPM_DEFAULT_VALUE: 120,
};
