import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IBitfCloseEvent } from '@interfaces';
import { EBitfApiSortDirection, EBitfCloseEventStatus, EBitfUiMessageType } from '@enums';
import { Collection, Sample } from '@common/core/models';
import { CollectionsService, LoaderService, ToastMessagesService } from '@web/core/services';
import { MatSelectionListChange } from '@angular/material/list';
import { tap } from 'rxjs/operators';
import { EDialogResponseDataType } from '@common/enums/common.enum';

@Component({
  selector: 'ss-sample-add-or-create-collection-dialog',
  templateUrl: './sample-add-or-create-collection-dialog.component.html',
  styleUrls: ['./sample-add-or-create-collection-dialog.component.scss'],
  standalone: false,
})
export class SampleAddOrCreateCollectionDialogComponent implements OnInit {
  pinnedCollections: Collection[] = [];
  otherCollections: Collection[] = [];
  selectedCollection: Collection;
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<SampleAddOrCreateCollectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { sample?: Sample; samples?: Sample[]; collection?: Collection },
    private collectionsService: CollectionsService,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.collectionsService
      .search<Collection>({ sorting: [{ property: 'createdOn', direction: EBitfApiSortDirection.DESC }] })
      .subscribe(response => {
        this.pinnedCollections = response.content.filter(collection => collection.isPinned);
        // .sort((a, b) => (a.label < b.label ? -1 : 1));
        this.otherCollections = response.content.filter(collection => !collection.isPinned);
        // .sort((a, b) => (a.label < b.label ? -1 : 1));
        this.isLoading = false;
      });
  }

  onCollectionSelected(changeEvent: MatSelectionListChange) {
    this.selectedCollection = changeEvent.options[0]?.value;
  }

  onOk() {
    if (this.selectedCollection) {
      if (this.dialogData.samples?.length) {
        this.loaderService.show();

        this.collectionsService
          .linkRel<any>({
            id: this.selectedCollection.id,
            relation: 'samples',
            relationId: 'bulk',
            body: { ids: this.dialogData.samples.map(sample => sample.id) },
          })
          .pipe(
            tap(() => {
              this.toastMessagesService.show({
                type: EBitfUiMessageType.SUCCESS,
                title: `Samples added to collection: ${this.selectedCollection.label}`,
              });
              this.dialogRef.close({
                status: EBitfCloseEventStatus.OK,
              } as IBitfCloseEvent<void>);
            })
          )
          .subscribe();
      } else if (this.dialogData.sample) {
        this.loaderService.show();

        this.collectionsService
          .linkRel<any>({
            id: this.selectedCollection.id,
            relation: 'samples',
            relationId: this.dialogData.sample.id,
          })
          .pipe(
            tap(() => {
              this.toastMessagesService.show({
                type: EBitfUiMessageType.SUCCESS,
                title: `Sample added to collection: ${this.selectedCollection.label}`,
              });
              this.dialogRef.close({
                status: EBitfCloseEventStatus.OK,
              } as IBitfCloseEvent<void>);
            })
          )
          .subscribe();
      }
    }
  }

  onCreateNewCollection() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: EDialogResponseDataType.OPEN_CREATE_COLLECTION_DIALOG,
    } as IBitfCloseEvent<string>);
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
