import { Component, Injector, Input } from '@angular/core';
import { SuperSampleButtonComponent } from '../super-sample-button.component';
import { FavoritesService, SamplesService } from '@web/core/services';
import { catchError, tap } from 'rxjs/operators';
import { ESignal, EWebStoreActions } from '@enums';
import { Store } from '@common/core/models';

@Component({
  selector: 'ss-sample-favorite-button',
  templateUrl: './sample-favorite-button.component.html',
  styleUrls: ['./sample-favorite-button.component.scss'],
  standalone: false,
})
export class SampleFavoriteButtonComponent extends SuperSampleButtonComponent {
  @Input() shouldShowAddToFavorite = true;

  constructor(
    protected injector: Injector,
    private favoritesService: FavoritesService,
    private samplesService: SamplesService
  ) {
    super(injector);
  }

  onAddToFavoriteSample($event) {
    this.toggleFavoriteSample(true);
    this.preventDefault($event);
  }

  onRemoveFromFavoriteSample($event) {
    this.toggleFavoriteSample(false);
    this.preventDefault($event);
  }

  onAddToFavoriteBulk($event) {
    this.toggleFavoriteBulk(true);
    this.preventDefault($event);
  }

  onRemoveFromFavoriteBulk($event) {
    this.toggleFavoriteBulk(false);
    this.preventDefault($event);
  }

  private toggleFavoriteSample(newFavoriteValue) {
    this.loaderService.show();
    this.beforeAction.emit(true);
    // NOTE: we store current values to restore in case of error
    const prevFavorite = this.sample.isFavorite;
    this.sample.isFavorite = newFavoriteValue;
    this.samplesService
      .patch({ body: this.sample })
      .pipe(
        tap(() => {
          // NOTE: User clicks a fav in the list sample
          // sync the current sample in case is not the same object as that one that is in the list
          // ex you set fav to a sample that is inside a search, but the current sample is not the same
          // object
          // and is set from a package samples list
          if (this.sample.id === this.storeService.store?.currentSample?.id) {
            this.storeService.setStore((store: Store) => {
              store.currentSample.isFavorite = newFavoriteValue;
            }, EWebStoreActions.UPDATE_CURRENT_SAMPLE);
          }
          // NOTE: User clicks a fav in the current sample
          // this is the opposite, we've to sync a list items that could be the same object as the
          // current sample
          const listSample = this.storeService.store.samplesList.find(
            sample => sample?.id === this.sample.id
          );
          if (listSample) {
            this.storeService.setStore(() => {
              listSample.isFavorite = newFavoriteValue;
            }, EWebStoreActions.UPDATE_SAMPLES_LIST);
          }
        }),
        tap(() => {
          // We've to trigger a signal to update the favorites list
          // this will be handled only in the fav list when the user remove a sample from the fav
          if (!newFavoriteValue) {
            this.appSessionService.signal$.next({
              action: ESignal.SAMPLE_REMOVED_FROM_FAVORITES,
              payload: { numberOfDeletedSamples: 1 },
            });
          }
        }),
        tap(() => this.actionCompleted.emit(true)),
        catchError(error => {
          this.sample.isFavorite = prevFavorite;
          return error;
        })
      )
      .subscribe();
  }

  private toggleFavoriteBulk(newFavoriteValue) {
    this.loaderService.show();
    this.beforeAction.emit(true);

    // NOTE: we store current values to restore in case of error
    const prevFavorites = this.samples.map(sample => sample.isFavorite);
    // NOTE we do optimistic update
    this.samples.forEach(sample => {
      sample.isFavorite = newFavoriteValue;
    });
    this.favoritesService
      .bulkToggleFavorites({
        body: { isFavorite: newFavoriteValue, ids: this.samples.map(sample => sample.id) },
      })
      .pipe(
        catchError(error => {
          this.samples.forEach((sample, index) => {
            sample.isFavorite = prevFavorites[index];
          });
          return error;
        }),
        // NOTE: User clicks a fav in the list sample
        // sync the current sample in case is not the same object as that one that is in the list
        // ex you set fav to a sample that is inside a search, but the current sample is not the same object
        // and is set from a package samples list
        tap(() => {
          const currentSample = this.samples.find(
            sample => sample.id === this.storeService.store?.currentSample?.id
          );
          if (currentSample) {
            this.storeService.setStore((store: Store) => {
              store.currentSample.isFavorite = newFavoriteValue;
            }, EWebStoreActions.UPDATE_CURRENT_SAMPLE);
          }
        }),
        tap(() => {
          // We've to trigger a signal to update the favorites list
          // this will be handled only in the fav list when the user remove a sample from the fav
          if (!newFavoriteValue) {
            this.appSessionService.signal$.next({
              action: ESignal.SAMPLE_REMOVED_FROM_FAVORITES,
              payload: { numberOfDeletedSamples: this.samples.length },
            });
          }
        }),
        tap(() => this.bulkActionCompleted.emit(true))
      )
      .subscribe();
  }
}
