<div class="row no-gutters mx-n2">
  <div class="col px-2">
    <h1 [ngClass]="{'mat-h2': isHandsetLayout}">Select {{minGenres}} or more genres you like</h1>
  </div>
</div>

<div class="row no-gutters mx-n2 pt-3 mb-3 overflow-y-auto overflow-x-hidden">
  <ss-item-card class="col-12 col-sm-4 col-md-3 mb-3 cursor-pointer px-2"
    (click)="onClick(genre)"
    *ngFor="let genre of genres; trackBy:trackByFn"
    [title]="genre.label"
    [imageUrl]="genre.coverUrl"
    [isSelected]="selectedGenres.has(genre.id)"></ss-item-card>
</div>

<div class="flex-grow-1">
  <!-- filler -->
</div>

<div class="row no-gutters mx-n2">
  <div class="col px-2 text-right">
    <button mat-flat-button
      type="submit"
      [disabled]="selectedGenres.size < minGenres"
      (click)="onSave()">Save</button>
  </div>
</div>