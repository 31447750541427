import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Filter, Tag } from '@models';
import { EApiCallStateNames } from '@enums';
import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';

@Component({
  selector: 'ss-mobile-filters-sidenav',
  templateUrl: './mobile-filters-sidenav.component.html',
  styleUrls: ['./mobile-filters-sidenav.component.scss'],
  standalone: false,
})
export class MobileFiltersSidenavComponent {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() service: {
    getFilters: (requestParams?: IBitfApiRequest) => Observable<IBitfApiResponse<Filter>>;
    getAutocomplete: (requestParams: IBitfApiRequest) => Observable<IBitfApiResponse<Tag[]>>;
  };
  @Input() closeFn: () => void;
}
