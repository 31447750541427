import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Pack, Sample } from '@common/core/models';
import { AppSessionService, StoreService } from '@web/core/services';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ss-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  standalone: false,
})
export class HeaderSearchComponent implements AfterViewInit {
  @Input() label: string;
  @Input() description: string;
  @Input() coverUrl: string;
  @Input() sample?: Sample;
  @Input() pack?: Pack;
  @Input() isPinned?: boolean;
  @Input() pinColor?: string;
  @Input() backLinkUrl?: string;
  @Input() showDownloadButton?: boolean;
  @Input() showEditButton?: boolean;
  @Input() showDeleteButton?: boolean;

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  @ViewChild('descriptionRef') descriptionElementRef: ElementRef<HTMLParagraphElement>;

  isDescriptionTruncated = false;
  isDescriptionExpaded = false;

  get isHandsetLayout() {
    return this.storeService.store.activeBreakpoints.isHandsetLayout;
  }

  constructor(
    private storeService: StoreService,
    public appSessionService: AppSessionService,
    private changeDetector: ChangeDetectorRef
  ) {
    fromEvent(window, 'resize')
      .pipe(debounceTime(150), takeUntilDestroyed())
      .subscribe(() => {
        this.setIsDescriptionTruncated();
      });
  }

  ngAfterViewInit(): void {
    this.setIsDescriptionTruncated();
    this.changeDetector.detectChanges();
  }

  onToggleDescriptionExpanded() {
    this.isDescriptionExpaded = !this.isDescriptionExpaded;
  }

  private setIsDescriptionTruncated() {
    const element = this.descriptionElementRef?.nativeElement;
    this.isDescriptionTruncated = element?.scrollHeight > element?.clientHeight;
  }

  onEdit() {
    this.edit.emit();
  }

  onDelete() {
    this.delete.emit();
  }
}
