import { Component, Injector, Input } from '@angular/core';
import { FeedbackService, SamplesService } from '@common/core/services';
import { catchError, tap } from 'rxjs/operators';
import { SuperSampleButtonComponent } from '../super-sample-button.component';
import { EAiFeedback, EWebStoreActions } from '@web/enums';
import { Store } from '@common/core/models';

@Component({
  selector: 'ss-sample-feedback-button',
  templateUrl: './sample-feedback-button.component.html',
  styleUrls: ['./sample-feedback-button.component.scss'],
  standalone: false,
})
export class SampleFeedbackButtonComponent extends SuperSampleButtonComponent {
  eAiFeedback = EAiFeedback;
  @Input() viewMode: EAiFeedback | 'BOTH' = 'BOTH';

  constructor(
    protected injector: Injector,
    private samplesService: SamplesService,
    private feedbackService: FeedbackService
  ) {
    super(injector);
  }

  onLikeFeedbackSample($event, sample) {
    if (sample.aiGeneration.aiFeedback === EAiFeedback.LIKE) {
      this.updateFeedbackSample('');
    } else {
      this.updateFeedbackSample(EAiFeedback.LIKE);
    }
    this.preventDefault($event);
  }

  onDislikeFeedbackSample($event, sample) {
    if (sample.aiGeneration.aiFeedback === EAiFeedback.DISLIKE) {
      this.updateFeedbackSample('');
    } else {
      this.updateFeedbackSample(EAiFeedback.DISLIKE);
    }
    this.preventDefault($event);
  }

  onLikeFeedbackBulk($event) {
    this.updateFeedbackBulk(EAiFeedback.LIKE);
    this.preventDefault($event);
  }

  onDislikeFeedbackBulk($event) {
    this.updateFeedbackBulk(EAiFeedback.DISLIKE);
    this.preventDefault($event);
  }

  private updateFeedbackSample(newFeedbackValue: EAiFeedback | '') {
    this.loaderService.show();
    this.beforeAction.emit(true);
    // NOTE: we store current values to restore in case of error
    const prevFeedback = this.sample.aiGeneration.aiFeedback;
    this.sample.aiGeneration.aiFeedback = newFeedbackValue;
    this.samplesService
      .patch({ body: this.sample })
      .pipe(
        tap(() => {
          if (this.sample.id === this.storeService.store?.currentSample?.id) {
            this.storeService.setStore((store: Store) => {
              store.currentSample.aiGeneration.aiFeedback = newFeedbackValue;
            }, EWebStoreActions.UPDATE_CURRENT_SAMPLE);
          }
          const listSample = this.storeService.store.samplesList.find(
            sample => sample?.id === this.sample.id
          );
          if (listSample) {
            this.storeService.setStore(() => {
              listSample.aiGeneration.aiFeedback = newFeedbackValue;
            }, EWebStoreActions.UPDATE_SAMPLES_LIST);
          }
        }),
        tap(response => {
          this.actionCompleted.emit(true);
        }),
        catchError(error => {
          this.sample.aiGeneration.aiFeedback = prevFeedback;
          return error;
        })
      )
      .subscribe();
  }

  private updateFeedbackBulk(newFeedbackValue: EAiFeedback | '') {
    this.loaderService.show();
    this.beforeAction.emit(true);

    // NOTE: we store current values to restore in case of error
    const prevFeedback = this.samples.map(sample => sample.aiGeneration.aiFeedback);
    // NOTE we do optimistic update
    this.samples.forEach(sample => {
      sample.aiGeneration.aiFeedback = newFeedbackValue;
    });
    this.feedbackService
      .bulkChangeFeedback({
        body: { aiFeedback: newFeedbackValue, ids: this.samples.map(sample => sample.id) },
      })
      .pipe(
        catchError(error => {
          this.samples.forEach((sample, index) => {
            sample.aiGeneration.aiFeedback = prevFeedback[index];
          });
          return error;
        }),
        tap(() => {
          const currentSample = this.samples.find(
            sample => sample.id === this.storeService.store?.currentSample?.id
          );
          if (currentSample) {
            this.storeService.setStore((store: Store) => {
              store.currentSample.aiGeneration.aiFeedback = newFeedbackValue;
            }, EWebStoreActions.UPDATE_CURRENT_SAMPLE);
          }
        }),
        tap(() => this.bulkActionCompleted.emit(true))
      )
      .subscribe();
  }
}
