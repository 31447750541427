import { Component, OnInit } from '@angular/core';

import { AppSessionService } from '@services';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  isAppReady = false;
  samplesFiltersApiRequestPart: BitfApiRequestPart;

  constructor(private appSessionService: AppSessionService) {}

  ngOnInit(): void {
    this.initApp();
  }

  private async initApp() {
    await this.appSessionService.init();
    this.isAppReady = true;

    if (environment.production) {
      const head = document.getElementsByTagName('head')[0];
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = '/assets/scripts/clarity.js';
      head.append(s);
    }
  }
}
