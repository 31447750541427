<ss-inline-loader [showOverlay]="true"
  *ngIf="isLoading"></ss-inline-loader>

<div class="d-flex flex-column h-100">
  <div>
    <div class="d-flex align-items-center justify-content-between px-3 py-2">
      <div class="d-flex align-items-center">
        <h2 class="m-0 text-color-white">Filters</h2>
        <a mat-button
          data-testid="reset-filters-button"
          class="link-accent ml-2 mat-label-small"
          (click)="onResetFilters()">Reset</a>
      </div>
      <button mat-icon-button
        (click)="onClose()"><mat-icon>close</mat-icon></button>
    </div>
    <mat-divider></mat-divider>

    <div class="d-flex flex-column px-3 pt-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="flex-grow-1 mr-2">
          <ss-search-samples-type (change)="updateFilters()"
            [filters]="filters"></ss-search-samples-type>
        </div>

        <button mat-stroked-button
          data-testid="key-selection-button"
          #matMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="keys"
          class="ss-menu-trigger-button">
          <span>Key</span>
          <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #keys
          class="ss-menu-transparent ss-menu-wide">
          <ss-search-samples-key [isLoading]="isLoading"
            (change)="updateFilters()"
            (closeMenu)="matMenuTrigger.closeMenu()"
            [filters]="filters"></ss-search-samples-key>
        </mat-menu>
      </div>

      <ss-search-samples-bpm class="mt-2"
        (change)="updateFilters()"
        [filters]="filters"></ss-search-samples-bpm>
    </div>
    <mat-divider></mat-divider>

    <mat-button-toggle-group data-testid="filter-category-toggle-group"
      (change)="onCategoryChange($event)"
      [value]="selectedCategory"
      [hideSingleSelectionIndicator]="true"
      class="ss-toggle-group-big mt-3">
      <mat-button-toggle [value]="eFilterCategory.INSTRUMENTS">Instruments</mat-button-toggle>
      <mat-button-toggle [value]="eFilterCategory.GENRES">Genres</mat-button-toggle>
      <mat-button-toggle [value]="eFilterCategory.LABELS">Label</mat-button-toggle>
      <mat-button-toggle [value]="eFilterCategory.MOOD">Mood</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="flex-grow overflow-y-auto">
    <div [ngSwitch]="selectedCategory"
      class="px-3 pt-3">
      <ss-search-samples-tags-selector *ngSwitchCase="eFilterCategory.INSTRUMENTS"
        (change)="updateFilters()"
        [filters]="filters"
        filterName="instruments"
        [hasChildren]="true"></ss-search-samples-tags-selector>

      <ss-search-samples-tags-selector *ngSwitchCase="eFilterCategory.GENRES"
        (change)="updateFilters()"
        [filters]="filters"
        filterName="genres"
        [hasChildren]="true"></ss-search-samples-tags-selector>

      <ss-search-samples-tags-selector *ngSwitchCase="eFilterCategory.LABELS"
        (change)="updateFilters()"
        [filters]="filters"
        filterName="labels"
        [hasChildren]="false"></ss-search-samples-tags-selector>

      <ss-search-samples-tags-selector *ngSwitchCase="eFilterCategory.MOOD"
        (change)="updateFilters()"
        [filters]="filters"
        filterName="moods"
        [hasChildren]="false"></ss-search-samples-tags-selector>
    </div>
  </div>
</div>